
import bgImg from '../../assets/images/subtle_grunge.png'

import styled from 'styled-components'

const PageContent = styled.main`
  position: relative;
  
  flex: 1;
  --grid-template-columns: max(5vw,2rem) 1fr max(5vw,2rem);
  ${ p => p.theme.grid}
  background-image: linear-gradient(105deg, rgba(var(--color-beige-rgb), 0.4),rgba(var(--color-beige-rgb), 0.4)), url(${bgImg});

  & * {
    grid-column: var(--grid-column, 2);
  }

  h1, h2, h3, h4, h5, h6 {
    text-align: var(--text-align, center);
  }

  p {
    text-align: var(--text-align, center);
    width: var(--paragraph-width);
    font-size: var(--font-size, var(--font-size-default));
    /* font-size: clamp(1.8rem, 2vw, 2.4rem); */
    margin: var(--margin-top, 0) auto var(--margin-bottom, calc(var(--font-size-default) * 1.5));
    
    &:first-child {
      --margin-top: 0;
    }

    &:last-child {
      --margin-bottom: 8rem;
    }
  }
`

export default PageContent
